// This file is autogenerated, do not modify manually, use 'npm run buildTranslationsObject'
export const translations = {
  action: {
    takepart: "action.takepart",
    resume: "action.resume",
    continue: "action.continue",
    accept: "action.accept",
    deny: "action.deny",
    logout: "action.logout",
    save: "action.save",
    send: "action.send",
    download: "action.download",
    next: "action.next",
    complete: "action.complete",
    previous: "action.previous",
    delete: "action.delete",
    cancel: "action.cancel",
    nextSummary: "action.nextSummary",
    goLive: "action.goLive",
    modify: "action.modify",
    addMore: "action.addMore",
    register: "action.register",
    login: "action.login",
    stopQuestionnaire: "action.stopQuestionnaire",
    saveAndRegister: "action.saveAndRegister",
    registerNow: "action.registerNow",
    personalizeNow: "action.personalizeNow",
    toDashboard: "action.toDashboard",
    newSurvey: "action.newSurvey",
    learnMore: "action.learnMore",
    finalizeQuestionnaire: "action.finalizeQuestionnaire",
    seeResults: "action.seeResults",
    answer: "action.answer",
    saveNewAnswer: "action.saveNewAnswer",
    forward: "action.forward",
    print: "action.print",
    csvDownload: "action.csvDownload",
    panelInfos: "action.panelInfos",
    startingPage: "action.startingPage",
    startNow: "action.startNow",
    loginGeneral: "action.loginGeneral",
    reworkSurvey: "action.reworkSurvey",
    requestPublish: "action.requestPublish",
    acceptSurvey: "action.acceptSurvey",
    denySurvey: "action.denySurvey",
    acceptAndPublishSurvey: "action.acceptAndPublishSurvey",
    questionnaireDetails: "action.questionnaireDetails",
    waitlistedSurvey: "action.waitlistedSurvey",
    close: "action.close",
    requestFurtherItems: "action.requestFurtherItems",
    preview: "action.preview",
    closePreview: "action.closePreview",
    confirmDeleteSelfAccount: "action.confirmDeleteSelfAccount",
    cancelDeleteSelfAccount: "action.cancelDeleteSelfAccount",
    activate: "action.activate",
    deactivate: "action.deactivate",
  },
  operator: { or: "operator.or", and: "operator.and" },
  feedback: { headline: "feedback.headline", text: "feedback.text", email: "feedback.email" },
  general: {
    loading: "general.loading",
    offlineError: "general.offlineError",
    offlineSavingInfo: "general.offlineSavingInfo",
    anErrorOccured: "general.anErrorOccured",
    tryAgainResearcher: "general.tryAgainResearcher",
    tryAgainParticipant: "general.tryAgainParticipant",
    requieredInput: "general.requieredInput",
    requiredLength: "general.requiredLength",
  },
  localError: {
    offlineSendingQuestionnaire: "localError.offlineSendingQuestionnaire",
    expiredAccessToQuestionnaire: "localError.expiredAccessToQuestionnaire",
  },
  login: {
    headline: "login.headline",
    text: "login.text",
    textResearcher: "login.textResearcher",
    invalidCredentials: "login.invalidCredentials",
    userPlaceholder: "login.userPlaceholder",
    passwordPlaceholder: "login.passwordPlaceholder",
    forgottenPassword: "login.forgottenPassword",
    newAtQuopinion: "login.newAtQuopinion",
    emailVerified: { title: "login.emailVerified.title", message: "login.emailVerified.message" },
    emailVerifiedMarket: {
      title: "login.emailVerifiedMarket.title",
      message: "login.emailVerifiedMarket.message",
    },
  },
  kpi: { opinion: "kpi.opinion", chashbalance: "kpi.chashbalance" },
  points: { sg: "points.sg", pl: "points.pl" },
  questions: {
    sg: "questions.sg",
    pl: "questions.pl",
    question: "questions.question",
    question_plural: "questions.question_plural",
  },
  days: { sg: "days.sg", pl: "days.pl", approxLeft: "days.approxLeft" },
  dailyAttributeQuestions: {
    headline: "dailyAttributeQuestions.headline",
    text: "dailyAttributeQuestions.text",
    over: "dailyAttributeQuestions.over",
  },
  headerSurvey: {
    pl: "headerSurvey.pl",
    newSurvey: "headerSurvey.newSurvey",
    still: "headerSurvey.still",
    till: "headerSurvey.till",
    topInfo: { clientSurvey: "headerSurvey.topInfo.clientSurvey" },
    mainTitle: { surveyName: "headerSurvey.mainTitle.surveyName" },
    all: "headerSurvey.all",
    over: "headerSurvey.over",
    current: "headerSurvey.current",
    done: "headerSurvey.done",
    check: "headerSurvey.check",
    noSurveys: {
      headline: "headerSurvey.noSurveys.headline",
      description: "headerSurvey.noSurveys.description",
      showMissedSurveys: "headerSurvey.noSurveys.showMissedSurveys",
    },
    timedInformation: {
      title: "headerSurvey.timedInformation.title",
      subTitle: "headerSurvey.timedInformation.subTitle",
    },
    answerTime: {
      yourTime: "headerSurvey.answerTime.yourTime",
      hours: "headerSurvey.answerTime.hours",
      today: "headerSurvey.answerTime.today",
      at: "headerSurvey.answerTime.at",
      oClock: "headerSurvey.answerTime.oClock",
      modal: {
        headline: "headerSurvey.answerTime.modal.headline",
        description: "headerSurvey.answerTime.modal.description",
      },
      fullSurvey: "headerSurvey.answerTime.fullSurvey",
      missedSurvey: "headerSurvey.answerTime.missedSurvey",
    },
  },
  infoSurveyItem: {
    deadline: { title: "infoSurveyItem.deadline.title", dummy: "infoSurveyItem.deadline.dummy" },
    participants: {
      title: "infoSurveyItem.participants.title",
      dummy: "infoSurveyItem.participants.dummy",
    },
    sizeLength: {
      title: "infoSurveyItem.sizeLength.title",
      dummy: "infoSurveyItem.sizeLength.dummy",
    },
    earnings: { title: "infoSurveyItem.earnings.title", dummy: "infoSurveyItem.earnings.dummy" },
  },
  additionalInfoItem: {
    title: "additionalInfoItem.title",
    contentPlacehoder: "additionalInfoItem.contentPlacehoder",
  },
  backOffice: { denyReason: "backOffice.denyReason" },
  pages: {
    Error: { title: "pages.Error.title", description: "pages.Error.description" },
    admin: {
      dashboard: {
        payment: {
          title: "pages.admin.dashboard.payment.title",
          payout: "pages.admin.dashboard.payment.payout",
          paymentHistory: "pages.admin.dashboard.payment.paymentHistory",
        },
        attributes: {
          title: "pages.admin.dashboard.attributes.title",
          intern: "pages.admin.dashboard.attributes.intern",
          create: "pages.admin.dashboard.attributes.create",
          externalScreenerRequests: "pages.admin.dashboard.attributes.externalScreenerRequests",
          externalRequestedDailies: "pages.admin.dashboard.attributes.externalRequestedDailies",
          general: "pages.admin.dashboard.attributes.general",
          dailies: "pages.admin.dashboard.attributes.dailies",
          attribute: "pages.admin.dashboard.attributes.attribute",
          deleteAttribute: "pages.admin.dashboard.attributes.deleteAttribute",
          verifyDeactivation: "pages.admin.dashboard.attributes.verifyDeactivation",
          verifyActivation: "pages.admin.dashboard.attributes.verifyActivation",
          sort: {
            date: "pages.admin.dashboard.attributes.sort.date",
            alphabetically: "pages.admin.dashboard.attributes.sort.alphabetically",
          },
        },
        billData: {
          title: "pages.admin.dashboard.billData.title",
          allBillingInformation: "pages.admin.dashboard.billData.allBillingInformation",
        },
      },
      RequestAttribute: {
        organization: "pages.admin.RequestAttribute.organization",
        activated: "pages.admin.RequestAttribute.activated",
        level: "pages.admin.RequestAttribute.level",
        attributeId: "pages.admin.RequestAttribute.attributeId",
        answerAmount: "pages.admin.RequestAttribute.answerAmount",
        priority: "pages.admin.RequestAttribute.priority",
        category: "pages.admin.RequestAttribute.category",
        description: "pages.admin.RequestAttribute.description",
        name: "pages.admin.RequestAttribute.name",
      },
    },
    Survey: {
      Admin: {
        createdBy: "pages.Survey.Admin.createdBy",
        adminChanges: {
          warning: "pages.Survey.Admin.adminChanges.warning",
          priceChanges: "pages.Survey.Admin.adminChanges.priceChanges",
          tooltip: "pages.Survey.Admin.adminChanges.tooltip",
        },
        templateChoice: {
          noTemplatesAvailable: "pages.Survey.Admin.templateChoice.noTemplatesAvailable",
        },
      },
      introduction: {
        title: "pages.Survey.introduction.title",
        description: "pages.Survey.introduction.description",
        disabled: "pages.Survey.introduction.disabled",
        selectionTitle: "pages.Survey.introduction.selectionTitle",
        teaserTitle: "pages.Survey.introduction.teaserTitle",
        start: "pages.Survey.introduction.start",
        availableSelections: {
          basic: {
            title: "pages.Survey.introduction.availableSelections.basic.title",
            description: "pages.Survey.introduction.availableSelections.basic.description",
            duration: "pages.Survey.introduction.availableSelections.basic.duration",
          },
        },
        unavailableSelections: {
          special: {
            title: "pages.Survey.introduction.unavailableSelections.special.title",
            description: "pages.Survey.introduction.unavailableSelections.special.description",
            duration: "pages.Survey.introduction.unavailableSelections.special.duration",
          },
          complex: {
            title: "pages.Survey.introduction.unavailableSelections.complex.title",
            description: "pages.Survey.introduction.unavailableSelections.complex.description",
            duration: "pages.Survey.introduction.unavailableSelections.complex.duration",
          },
        },
        explanation: {
          first: {
            title: "pages.Survey.introduction.explanation.first.title",
            description: "pages.Survey.introduction.explanation.first.description",
            descriptionShort: "pages.Survey.introduction.explanation.first.descriptionShort",
          },
          second: {
            title: "pages.Survey.introduction.explanation.second.title",
            description: "pages.Survey.introduction.explanation.second.description",
            descriptionShort: "pages.Survey.introduction.explanation.second.descriptionShort",
          },
          third: {
            title: "pages.Survey.introduction.explanation.third.title",
            description: "pages.Survey.introduction.explanation.third.description",
            descriptionShort: "pages.Survey.introduction.explanation.third.descriptionShort",
          },
          fourth: {
            title: "pages.Survey.introduction.explanation.fourth.title",
            descriptionShort: "pages.Survey.introduction.explanation.fourth.descriptionShort",
          },
        },
      },
      surveyTitle: "pages.Survey.surveyTitle",
    },
    surveyResults: {
      inPercent: "pages.surveyResults.inPercent",
      deviationFrom100: "pages.surveyResults.deviationFrom100",
      answer: "pages.surveyResults.answer",
      shareInPercent: "pages.surveyResults.shareInPercent",
      absoluteNrAnswers: "pages.surveyResults.absoluteNrAnswers",
      additionalFreeTextAnswer: "pages.surveyResults.additionalFreeTextAnswer",
      additionalNumberAnswer: "pages.surveyResults.additionalNumberAnswer",
      freeTexts: "pages.surveyResults.freeTexts",
      numericalAnswers: "pages.surveyResults.numericalAnswers",
      answerOption: "pages.surveyResults.answerOption",
      withCondition: "pages.surveyResults.withCondition",
      position: "pages.surveyResults.position",
    },
    dashboard: {
      researcher: {
        yourSurveys: "pages.dashboard.researcher.yourSurveys",
        text: "pages.dashboard.researcher.text",
        infoDetails: {
          activeParticipants: "pages.dashboard.researcher.infoDetails.activeParticipants",
        },
        states: {
          NEW: "pages.dashboard.researcher.states.NEW",
          ACCEPTED: "pages.dashboard.researcher.states.ACCEPTED",
          REJECTED: "pages.dashboard.researcher.states.REJECTED",
          PUBLISH_REQUESTED: "pages.dashboard.researcher.states.PUBLISH_REQUESTED",
          PUBLISHED: "pages.dashboard.researcher.states.PUBLISHED",
          ENDED: "pages.dashboard.researcher.states.ENDED",
        },
        complete: "pages.dashboard.researcher.complete",
        details: "pages.dashboard.researcher.details",
        links: {
          yourOrder: "pages.dashboard.researcher.links.yourOrder",
          personalInfo: "pages.dashboard.researcher.links.personalInfo",
          completed: "pages.dashboard.researcher.links.completed",
          billData: "pages.dashboard.researcher.links.billData",
          dashboard: "pages.dashboard.researcher.links.dashboard",
          surveys: "pages.dashboard.researcher.links.surveys",
        },
        createdAt: "pages.dashboard.researcher.createdAt",
        beginning: "pages.dashboard.researcher.beginning",
        company: "pages.dashboard.researcher.company",
        account: {
          title: "pages.dashboard.researcher.account.title",
          time: "pages.dashboard.researcher.account.time",
          timeValue: "pages.dashboard.researcher.account.timeValue",
          panelValue: "pages.dashboard.researcher.account.panelValue",
          runTime: "pages.dashboard.researcher.account.runTime",
          runTimeValue: "pages.dashboard.researcher.account.runTimeValue",
          download: "pages.dashboard.researcher.account.download",
          orderHeadline: "pages.dashboard.researcher.account.orderHeadline",
          editDuration: "pages.dashboard.researcher.account.editDuration",
        },
        filter: {
          draft: "pages.dashboard.researcher.filter.draft",
          rejected: "pages.dashboard.researcher.filter.rejected",
        },
      },
    },
    dataProtection: {
      title: "pages.dataProtection.title",
      marketplace: "pages.dataProtection.marketplace",
      researcher: {
        short: {
          bodyTitle: "pages.dataProtection.researcher.short.bodyTitle",
          text: "pages.dataProtection.researcher.short.text",
          bodySubtitle: "pages.dataProtection.researcher.short.bodySubtitle",
          dataIntro: "pages.dataProtection.researcher.short.dataIntro",
          dataFirst: "pages.dataProtection.researcher.short.dataFirst",
          dataSecond: "pages.dataProtection.researcher.short.dataSecond",
          dataThird: "pages.dataProtection.researcher.short.dataThird",
          reworkIntro: "pages.dataProtection.researcher.short.reworkIntro",
          reworkFirst: "pages.dataProtection.researcher.short.reworkFirst",
          reworkSecond: "pages.dataProtection.researcher.short.reworkSecond",
          reworkThird: "pages.dataProtection.researcher.short.reworkThird",
          reworkFourth: "pages.dataProtection.researcher.short.reworkFourth",
          reworkFifth: "pages.dataProtection.researcher.short.reworkFifth",
          paragraph1: "pages.dataProtection.researcher.short.paragraph1",
          paragraph2: "pages.dataProtection.researcher.short.paragraph2",
          paragraph3: "pages.dataProtection.researcher.short.paragraph3",
          paragraph4: "pages.dataProtection.researcher.short.paragraph4",
          contact: "pages.dataProtection.researcher.short.contact",
          authority: "pages.dataProtection.researcher.short.authority",
        },
      },
      participant: { bodyTitle: "pages.dataProtection.participant.bodyTitle" },
      declaration: {
        title: "pages.dataProtection.declaration.title",
        introduction: "pages.dataProtection.declaration.introduction",
        first: "pages.dataProtection.declaration.first",
        second: "pages.dataProtection.declaration.second",
        third: "pages.dataProtection.declaration.third",
      },
    },
    FAQ: {
      frequentlyAskedQuestions: "pages.FAQ.frequentlyAskedQuestions",
      participant: {
        introHeadline: "pages.FAQ.participant.introHeadline",
        introDescription: "pages.FAQ.participant.introDescription",
        text: "pages.FAQ.participant.text",
        title1: "pages.FAQ.participant.title1",
        title2: "pages.FAQ.participant.title2",
        title3: "pages.FAQ.participant.title3",
        question1: "pages.FAQ.participant.question1",
        answer1: "pages.FAQ.participant.answer1",
        question2: "pages.FAQ.participant.question2",
        answer2: "pages.FAQ.participant.answer2",
        question3: "pages.FAQ.participant.question3",
        answer3: "pages.FAQ.participant.answer3",
        question4: "pages.FAQ.participant.question4",
        answer4: "pages.FAQ.participant.answer4",
        question5: "pages.FAQ.participant.question5",
        answer5: "pages.FAQ.participant.answer5",
        question6: "pages.FAQ.participant.question6",
        answer6: "pages.FAQ.participant.answer6",
        question7: "pages.FAQ.participant.question7",
        answer7: "pages.FAQ.participant.answer7",
        question8: "pages.FAQ.participant.question8",
        answer8: "pages.FAQ.participant.answer8",
        question9: "pages.FAQ.participant.question9",
        answer9: "pages.FAQ.participant.answer9",
        question10: "pages.FAQ.participant.question10",
        answer10: "pages.FAQ.participant.answer10",
        question11: "pages.FAQ.participant.question11",
        answer11: "pages.FAQ.participant.answer11",
      },
      researcher: {
        introHeadline: "pages.FAQ.researcher.introHeadline",
        introDescription: "pages.FAQ.researcher.introDescription",
        title: "pages.FAQ.researcher.title",
        text: "pages.FAQ.researcher.text",
        question1: "pages.FAQ.researcher.question1",
        answer1: "pages.FAQ.researcher.answer1",
        question2: "pages.FAQ.researcher.question2",
        answer2: "pages.FAQ.researcher.answer2",
        question3: "pages.FAQ.researcher.question3",
        answer3: "pages.FAQ.researcher.answer3",
        question4: "pages.FAQ.researcher.question4",
        answer4: "pages.FAQ.researcher.answer4",
        question5: "pages.FAQ.researcher.question5",
        answer5: "pages.FAQ.researcher.answer5",
        question6: "pages.FAQ.researcher.question6",
        answer6: "pages.FAQ.researcher.answer6",
      },
    },
    termsConditions: {
      researcher: {
        title: "pages.termsConditions.researcher.title",
        bodyTitle: "pages.termsConditions.researcher.bodyTitle",
        text: "pages.termsConditions.researcher.text",
        bodySubtitle: "pages.termsConditions.researcher.bodySubtitle",
        textSubtitle: "pages.termsConditions.researcher.textSubtitle",
      },
      participant: { title: "pages.termsConditions.participant.title" },
    },
    Impressum: {
      introHeadline: "pages.Impressum.introHeadline",
      introDescription: "pages.Impressum.introDescription",
      title: "pages.Impressum.title",
      text: "pages.Impressum.text",
      address: {
        companyName: "pages.Impressum.address.companyName",
        street: "pages.Impressum.address.street",
        city: "pages.Impressum.address.city",
        country: "pages.Impressum.address.country",
      },
      details: {
        phone: {
          title: "pages.Impressum.details.phone.title",
          number: "pages.Impressum.details.phone.number",
          faxTitle: "pages.Impressum.details.phone.faxTitle",
          faxNumber: "pages.Impressum.details.phone.faxNumber",
        },
        email: {
          title: "pages.Impressum.details.email.title",
          address: "pages.Impressum.details.email.address",
        },
        contact: {
          user: "pages.Impressum.details.contact.user",
          researcher: "pages.Impressum.details.contact.researcher",
        },
        register: {
          court: {
            title: "pages.Impressum.details.register.court.title",
            relatedCourt: "pages.Impressum.details.register.court.relatedCourt",
            registerNumberTitle: "pages.Impressum.details.register.court.registerNumberTitle",
            "registerNumber:": "pages.Impressum.details.register.court.registerNumber:",
            locationTitle: "pages.Impressum.details.register.court.locationTitle",
            location: "pages.Impressum.details.register.court.location",
          },
          UstId: {
            title: "pages.Impressum.details.register.UstId.title",
            number: "pages.Impressum.details.register.UstId.number",
          },
        },
        director: {
          title: "pages.Impressum.details.director.title",
          directorOne: "pages.Impressum.details.director.directorOne",
          directorTwo: "pages.Impressum.details.director.directorTwo",
        },
      },
    },
    landingpage: {
      dataPrivacy: "pages.landingpage.dataPrivacy",
      researcher: {
        headline: "pages.landingpage.researcher.headline",
        subtitle: "pages.landingpage.researcher.subtitle",
        description1: "pages.landingpage.researcher.description1",
        description2: "pages.landingpage.researcher.description2",
        description3: "pages.landingpage.researcher.description3",
        why: "pages.landingpage.researcher.why",
        whySubheadline: "pages.landingpage.researcher.whySubheadline",
        goingTo: "pages.landingpage.researcher.goingTo",
        participantArea: "pages.landingpage.researcher.participantArea",
        bookCall: "pages.landingpage.researcher.bookCall",
        createSurvey: "pages.landingpage.researcher.createSurvey",
        targetGroup: {
          title: "pages.landingpage.researcher.targetGroup.title",
          firstGroup: {
            title: "pages.landingpage.researcher.targetGroup.firstGroup.title",
            description: "pages.landingpage.researcher.targetGroup.firstGroup.description",
          },
          secondGroup: {
            title: "pages.landingpage.researcher.targetGroup.secondGroup.title",
            description: "pages.landingpage.researcher.targetGroup.secondGroup.description",
          },
          thirdGroup: {
            title: "pages.landingpage.researcher.targetGroup.thirdGroup.title",
            description: "pages.landingpage.researcher.targetGroup.thirdGroup.description",
          },
        },
        fast: {
          headline: "pages.landingpage.researcher.fast.headline",
          description: "pages.landingpage.researcher.fast.description",
        },
        quality: {
          headline: "pages.landingpage.researcher.quality.headline",
          description: "pages.landingpage.researcher.quality.description",
        },
        transparent: {
          headline: "pages.landingpage.researcher.transparent.headline",
          description: "pages.landingpage.researcher.transparent.description",
        },
        steps: {
          title: "pages.landingpage.researcher.steps.title",
          first: {
            title: "pages.landingpage.researcher.steps.first.title",
            description: "pages.landingpage.researcher.steps.first.description",
          },
          second: {
            title: "pages.landingpage.researcher.steps.second.title",
            description: "pages.landingpage.researcher.steps.second.description",
          },
          third: {
            title: "pages.landingpage.researcher.steps.third.title",
            description: "pages.landingpage.researcher.steps.third.description",
          },
        },
        carousel: {
          headline: "pages.landingpage.researcher.carousel.headline",
          first: {
            titel: "pages.landingpage.researcher.carousel.first.titel",
            description: "pages.landingpage.researcher.carousel.first.description",
          },
          second: {
            titel: "pages.landingpage.researcher.carousel.second.titel",
            description: "pages.landingpage.researcher.carousel.second.description",
          },
          third: {
            titel: "pages.landingpage.researcher.carousel.third.titel",
            description: "pages.landingpage.researcher.carousel.third.description",
          },
        },
        contact: {
          headline: "pages.landingpage.researcher.contact.headline",
          description: "pages.landingpage.researcher.contact.description",
          placeholderName: "pages.landingpage.researcher.contact.placeholderName",
          placeholderEmail: "pages.landingpage.researcher.contact.placeholderEmail",
          label: "pages.landingpage.researcher.contact.label",
        },
        dataSecurity: "pages.landingpage.researcher.dataSecurity",
      },
      participant: {
        headline: "pages.landingpage.participant.headline",
        subtitle: "pages.landingpage.participant.subtitle",
        subtitle2: "pages.landingpage.participant.subtitle2",
        subtitle3: "pages.landingpage.participant.subtitle3",
        headlineRegister: "pages.landingpage.participant.headlineRegister",
        usp: {
          headline: "pages.landingpage.participant.usp.headline",
          subheadline: "pages.landingpage.participant.usp.subheadline",
          first: "pages.landingpage.participant.usp.first",
          firstSubtitle: "pages.landingpage.participant.usp.firstSubtitle",
          second: "pages.landingpage.participant.usp.second",
          secondSubtitle: "pages.landingpage.participant.usp.secondSubtitle",
          third: "pages.landingpage.participant.usp.third",
          thirdSubtitle: "pages.landingpage.participant.usp.thirdSubtitle",
          fourth: "pages.landingpage.participant.usp.fourth",
          sixth: "pages.landingpage.participant.usp.sixth",
          seventh: "pages.landingpage.participant.usp.seventh",
        },
      },
    },
    contact: {
      participant: {
        title: "pages.contact.participant.title",
        description: "pages.contact.participant.description",
        success: "pages.contact.participant.success",
        error: "pages.contact.participant.error",
      },
      researcher: {
        title: "pages.contact.researcher.title",
        description: "pages.contact.researcher.description",
        nameValidation: "pages.contact.researcher.nameValidation",
        nameValidationLength: "pages.contact.researcher.nameValidationLength",
        messageValidation: "pages.contact.researcher.messageValidation",
        phoneBack: "pages.contact.researcher.phoneBack",
        success: "pages.contact.researcher.success",
        error: "pages.contact.researcher.error",
      },
      form: {
        researcher: {
          title: "pages.contact.form.researcher.title",
          mr: "pages.contact.form.researcher.mr",
          mrs: "pages.contact.form.researcher.mrs",
          firstName: "pages.contact.form.researcher.firstName",
          lastName: "pages.contact.form.researcher.lastName",
          mail: "pages.contact.form.researcher.mail",
          phoneNr: "pages.contact.form.researcher.phoneNr",
          companyName: "pages.contact.form.researcher.companyName",
          companySector: "pages.contact.form.researcher.companySector",
          position: "pages.contact.form.researcher.position",
          validation: {
            titleLength: "pages.contact.form.researcher.validation.titleLength",
            firstNameLength: "pages.contact.form.researcher.validation.firstNameLength",
            lastNameLength: "pages.contact.form.researcher.validation.lastNameLength",
            companyNameLength: "pages.contact.form.researcher.validation.companyNameLength",
            companySectorLength: "pages.contact.form.researcher.validation.companySectorLength",
            positionLength: "pages.contact.form.researcher.validation.positionLength",
            subjectLength: "pages.contact.form.researcher.validation.subjectLength",
            emailType: "pages.contact.form.researcher.validation.emailType",
            phoneNrType: "pages.contact.form.researcher.validation.phoneNrType",
            requiredPhoneNr: "pages.contact.form.researcher.validation.requiredPhoneNr",
            requiredLastName: "pages.contact.form.researcher.validation.requiredLastName",
            requiredTitle: "pages.contact.form.researcher.validation.requiredTitle",
            requiredSubject: "pages.contact.form.researcher.validation.requiredSubject",
            requiredMessage: "pages.contact.form.researcher.validation.requiredMessage",
          },
        },
        name: "pages.contact.form.name",
        nameValidation: "pages.contact.form.nameValidation",
        nameValidationLength: "pages.contact.form.nameValidationLength",
        phone: "pages.contact.form.phone",
        phoneType: "pages.contact.form.phoneType",
        subject: "pages.contact.form.subject",
        message: "pages.contact.form.message",
        validationMessage: "pages.contact.form.validationMessage",
      },
    },
    forgottenPassword: {
      headline: "pages.forgottenPassword.headline",
      description: "pages.forgottenPassword.description",
      service: "pages.forgottenPassword.service",
      notification: "pages.forgottenPassword.notification",
      confirm: "pages.forgottenPassword.confirm",
      spamCheck: "pages.forgottenPassword.spamCheck",
      researcher: {
        headline: "pages.forgottenPassword.researcher.headline",
        description: "pages.forgottenPassword.researcher.description",
        service: "pages.forgottenPassword.researcher.service",
        notification: "pages.forgottenPassword.researcher.notification",
        spamCheck: "pages.forgottenPassword.researcher.spamCheck",
      },
    },
    Questionnaire: {
      cancelDialog: {
        title: "pages.Questionnaire.cancelDialog.title",
        description: "pages.Questionnaire.cancelDialog.description",
      },
      warning: {
        headline: "pages.Questionnaire.warning.headline",
        description: "pages.Questionnaire.warning.description",
      },
    },
  },
  panel: {
    generalInfo: {
      multiSelect: "panel.generalInfo.multiSelect",
      distributionWarning: "panel.generalInfo.distributionWarning",
      validStatus: "panel.generalInfo.validStatus",
      multiQuestionLevel: "panel.generalInfo.multiQuestionLevel",
      multiSelectHousehold: "panel.generalInfo.multiSelectHousehold",
      loginRequired: "panel.generalInfo.loginRequired",
    },
    educationLevel: {
      title: "panel.educationLevel.title",
      infotext: "panel.educationLevel.infotext",
      noDegree: "panel.educationLevel.noDegree",
      lowerSecondarySchool: "panel.educationLevel.lowerSecondarySchool",
      secondarySchool: "panel.educationLevel.secondarySchool",
      advancedTechnicalCollege: "panel.educationLevel.advancedTechnicalCollege",
      highschool: "panel.educationLevel.highschool",
      completedStudies: "panel.educationLevel.completedStudies",
    },
    familyStatus: {
      engaged: "panel.familyStatus.engaged",
      single: "panel.familyStatus.single",
      married: "panel.familyStatus.married",
      divorced: "panel.familyStatus.divorced",
      seperated: "panel.familyStatus.seperated",
      widowed: "panel.familyStatus.widowed",
      livingWithPartner: "panel.familyStatus.livingWithPartner",
    },
    children: {
      none: "panel.children.none",
      one: "panel.children.one",
      two: "panel.children.two",
      three: "panel.children.three",
      four: "panel.children.four",
      fiveOrMore: "panel.children.fiveOrMore",
    },
    gender: {
      male: "panel.gender.male",
      female: "panel.gender.female",
      divers: "panel.gender.divers",
    },
    household: {
      single: "panel.household.single",
      twoPerson: "panel.household.twoPerson",
      threePerson: "panel.household.threePerson",
      fourPerson: "panel.household.fourPerson",
      fivePerson: "panel.household.fivePerson",
      sixPerson: "panel.household.sixPerson",
      moreThanSixPerson: "panel.household.moreThanSixPerson",
    },
    populationDensity: {
      low: "panel.populationDensity.low",
      middle: "panel.populationDensity.middle",
      high: "panel.populationDensity.high",
    },
    individualAttributes: {
      category1: "panel.individualAttributes.category1",
      category2: "panel.individualAttributes.category2",
      category3: "panel.individualAttributes.category3",
    },
    attributeQuestions: {
      q1SportType: "panel.attributeQuestions.q1SportType",
      q1Pet: "panel.attributeQuestions.q1Pet",
      q2PetType: "panel.attributeQuestions.q2PetType",
      q1Sector: "panel.attributeQuestions.q1Sector",
    },
    attributeAnswers: {
      answers: "panel.attributeAnswers.answers",
      saveAttribute: "panel.attributeAnswers.saveAttribute",
      soccerTrue: "panel.attributeAnswers.soccerTrue",
      basketBallTrue: "panel.attributeAnswers.basketBallTrue",
      petTrue: "panel.attributeAnswers.petTrue",
      petFalse: "panel.attributeAnswers.petFalse",
      catTrue: "panel.attributeAnswers.catTrue",
      dogTrue: "panel.attributeAnswers.dogTrue",
      bankTrue: "panel.attributeAnswers.bankTrue",
      agricultureTrue: "panel.attributeAnswers.agricultureTrue",
      attributeChoice: "panel.attributeAnswers.attributeChoice",
      furtherAttributes: "panel.attributeAnswers.furtherAttributes",
      furtherAttributesTooltip: "panel.attributeAnswers.furtherAttributesTooltip",
    },
    income: {
      lessThanOneFive: "panel.income.lessThanOneFive",
      oneFiveToTwoFive: "panel.income.oneFiveToTwoFive",
      twoFiveToThreeFive: "panel.income.twoFiveToThreeFive",
      threeFiveToFourFive: "panel.income.threeFiveToFourFive",
      moreThanFourFive: "panel.income.moreThanFourFive",
      notSpecified: "panel.income.notSpecified",
    },
    hometown: "panel.hometown",
    zip: "panel.zip",
    newAttributeHeadline: "panel.newAttributeHeadline",
    newAttributeText: "panel.newAttributeText",
    requestSuccessful: "panel.requestSuccessful",
    requestError: "panel.requestError",
    newAttributeButton: "panel.newAttributeButton",
    requestedAttribute: "panel.requestedAttribute",
    questionnairePageError: "panel.questionnairePageError",
  },
  questionnaire: {
    formPlaceholder: "questionnaire.formPlaceholder",
    formPlaceholderInstruction: "questionnaire.formPlaceholderInstruction",
    question: "questionnaire.question",
    next: "questionnaire.next",
    addNewQuestion: "questionnaire.addNewQuestion",
    yourQuestionnaire: "questionnaire.yourQuestionnaire",
    questionnaireRejectedHeadline: "questionnaire.questionnaireRejectedHeadline",
    endOfQuestionnaireHeadline: "questionnaire.endOfQuestionnaireHeadline",
    endOfQuestionnaireDescription: "questionnaire.endOfQuestionnaireDescription",
    questionTypes: {
      instruction: "questionnaire.questionTypes.instruction",
      freeText: "questionnaire.questionTypes.freeText",
      singleChoice: "questionnaire.questionTypes.singleChoice",
      multipleChoice: "questionnaire.questionTypes.multipleChoice",
      stepRating: "questionnaire.questionTypes.stepRating",
      starRating: "questionnaire.questionTypes.starRating",
      matrix: "questionnaire.questionTypes.matrix",
      password: "questionnaire.questionTypes.password",
      ranking: "questionnaire.questionTypes.ranking",
    },
    questionCondition: {
      title: "questionnaire.questionCondition.title",
      removeCompleteSelection: "questionnaire.questionCondition.removeCompleteSelection",
      for: "questionnaire.questionCondition.for",
    },
    slider: {
      left: "questionnaire.slider.left",
      middle: "questionnaire.slider.middle",
      right: "questionnaire.slider.right",
      showNumeric: "questionnaire.slider.showNumeric",
      scala: "questionnaire.slider.scala",
      label: "questionnaire.slider.label",
      validation: {
        valueTooBig: "questionnaire.slider.validation.valueTooBig",
        valueTooSmall: "questionnaire.slider.validation.valueTooSmall",
        rightBiggerThanLeft: "questionnaire.slider.validation.rightBiggerThanLeft",
        onlyNumbers: "questionnaire.slider.validation.onlyNumbers",
      },
    },
    freeText: { infoBodyText: "questionnaire.freeText.infoBodyText" },
    singleChoice: {
      skipTo: {
        endQuestionnaire: "questionnaire.singleChoice.skipTo.endQuestionnaire",
        removeSkipTo: "questionnaire.singleChoice.skipTo.removeSkipTo",
      },
      addAdditionalAnswer: "questionnaire.singleChoice.addAdditionalAnswer",
    },
    choice: { numericalField: "questionnaire.choice.numericalField" },
    starRating: { starsCount: "questionnaire.starRating.starsCount" },
    matrix: {
      rowValues: "questionnaire.matrix.rowValues",
      columnValues: "questionnaire.matrix.columnValues",
    },
    password: {
      title: "questionnaire.password.title",
      inputPlaceholder: "questionnaire.password.inputPlaceholder",
      notMatching: "questionnaire.password.notMatching",
      infoTitle: "questionnaire.password.infoTitle",
      infoModalBody: "questionnaire.password.infoModalBody",
    },
    toolTip: {
      additionalFreeText: "questionnaire.toolTip.additionalFreeText",
      additionalFreeTextDeactivated: "questionnaire.toolTip.additionalFreeTextDeactivated",
      additionalNumberField: "questionnaire.toolTip.additionalNumberField",
      additionalNumberFieldDeactivated: "questionnaire.toolTip.additionalNumberFieldDeactivated",
      skipToLogic: "questionnaire.toolTip.skipToLogic",
      previewMobileView: "questionnaire.toolTip.previewMobileView",
      toggleDesktopPreview: "questionnaire.toolTip.toggleDesktopPreview",
      toggleMobilePreview: "questionnaire.toolTip.toggleMobilePreview",
      questionSkipCondition: "questionnaire.toolTip.questionSkipCondition",
      questionDiscardFromComplexity: "questionnaire.toolTip.questionDiscardFromComplexity",
    },
    icon: { numberSubtitel: "questionnaire.icon.numberSubtitel" },
    validation: {
      characters: "questionnaire.validation.characters",
      title: "questionnaire.validation.title",
      titleMaxLength: "questionnaire.validation.titleMaxLength",
      instruction: {
        requirement: "questionnaire.validation.instruction.requirement",
        maxLength: "questionnaire.validation.instruction.maxLength",
        url: "questionnaire.validation.instruction.url",
      },
      answers: {
        minCount: "questionnaire.validation.answers.minCount",
        maxCount: "questionnaire.validation.answers.maxCount",
        requiredTitle: "questionnaire.validation.answers.requiredTitle",
        length: "questionnaire.validation.answers.length",
        skipTo: "questionnaire.validation.answers.skipTo",
      },
      rating: {
        left: "questionnaire.validation.rating.left",
        right: "questionnaire.validation.rating.right",
      },
      starRating: { required: "questionnaire.validation.starRating.required" },
      password: {
        required: "questionnaire.validation.password.required",
        length: "questionnaire.validation.password.length",
        minCount: "questionnaire.validation.password.minCount",
        maxCount: "questionnaire.validation.password.maxCount",
      },
      additionalNumberField: "questionnaire.validation.additionalNumberField",
      allQuestionsAnswered: "questionnaire.validation.allQuestionsAnswered",
    },
    placeholder: {
      questionTextField: "questionnaire.placeholder.questionTextField",
      answerTextField: "questionnaire.placeholder.answerTextField",
      instructionTextField: "questionnaire.placeholder.instructionTextField",
      pleaseEnter: "questionnaire.placeholder.pleaseEnter",
      pleaseSelect: "questionnaire.placeholder.pleaseSelect",
      pleaseEnterText: "questionnaire.placeholder.pleaseEnterText",
      pleaseEnterNumber: "questionnaire.placeholder.pleaseEnterNumber",
    },
    mediaUpload: "questionnaire.mediaUpload",
    uploadErrors: {
      "413": "questionnaire.uploadErrors.413",
      default: "questionnaire.uploadErrors.default",
    },
    videoError: "questionnaire.videoError",
    audioError: "questionnaire.audioError",
    downloadError: "questionnaire.downloadError",
    panelPageError: "questionnaire.panelPageError",
  },
  summary: {
    panel: {
      headline: "summary.panel.headline",
      basedOn: "summary.panel.basedOn",
      basedOnYourQuestionnaire: "summary.panel.basedOnYourQuestionnaire",
      size: {
        title: "summary.panel.size.title",
        trend: "summary.panel.size.trend",
        wellGrounded: "summary.panel.size.wellGrounded",
        detailed: "summary.panel.size.detailed",
        modalText: "summary.panel.size.modalText",
        participants: "summary.panel.size.participants",
        participantsShort: "summary.panel.size.participantsShort",
      },
      gender: {
        title: "summary.panel.gender.title",
        all: "summary.panel.gender.all",
        modalText: "summary.panel.gender.modalText",
      },
      age: {
        title: "summary.panel.age.title",
        min: "summary.panel.age.min",
        max: "summary.panel.age.max",
        modalText: "summary.panel.age.modalText",
        generalDeviationInfo: "summary.panel.age.generalDeviationInfo",
      },
      household: {
        title: "summary.panel.household.title",
        income: "summary.panel.household.income",
        value: "summary.panel.household.value",
        modalText: "summary.panel.household.modalText",
      },
      income: {
        title: "summary.panel.income.title",
        noMatter: "summary.panel.income.noMatter",
        modalText: "summary.panel.income.modalText",
      },
      individual: { noneSelected: "summary.panel.individual.noneSelected" },
      region: {
        title: "summary.panel.region.title",
        add: "summary.panel.region.add",
        save: "summary.panel.region.save",
        noneSelected: "summary.panel.region.noneSelected",
        type: { title: "summary.panel.region.type.title" },
        characteristic: {
          title: {
            Leitregion: "summary.panel.region.characteristic.title.Leitregion",
            Reisegebiet: "summary.panel.region.characteristic.title.Reisegebiet",
            Bundesland: "summary.panel.region.characteristic.title.Bundesland",
            Bevölkerungsdichte: "summary.panel.region.characteristic.title.Bevölkerungsdichte",
            Kreis: "summary.panel.region.characteristic.title.Kreis",
            none: "summary.panel.region.characteristic.title.none",
          },
        },
      },
      education: "summary.panel.education",
      familyStatus: "summary.panel.familyStatus",
      children: "summary.panel.children",
      under18: "summary.panel.under18",
      freeAttributes: {
        title: "summary.panel.freeAttributes.title",
        text: "summary.panel.freeAttributes.text",
        text2: "summary.panel.freeAttributes.text2",
        category: "summary.panel.freeAttributes.category",
        feature: "summary.panel.freeAttributes.feature",
        value: "summary.panel.freeAttributes.value",
      },
      thereof: "summary.panel.thereof",
    },
    questionnaire: {
      replies: "summary.questionnaire.replies",
      subQuestions: "summary.questionnaire.subQuestions",
      subQuestion: "summary.questionnaire.subQuestion",
      subPrefix: "summary.questionnaire.subPrefix",
      stars: "summary.questionnaire.stars",
      all: "summary.questionnaire.all",
    },
    price: {
      currency: "summary.price.currency",
      headline: "summary.price.headline",
      participants: "summary.price.participants",
      marge: "summary.price.marge",
      "marge-info": "summary.price.marge-info",
      taxes: "summary.price.taxes",
      taxesIncl: "summary.price.taxesIncl",
      finalPrice: "summary.price.finalPrice",
      taxesGeneral: "summary.price.taxesGeneral",
      estimatedPrice: "summary.price.estimatedPrice",
      automaticPriceCalculation: "summary.price.automaticPriceCalculation",
      reactiveAutomaticPriceCalculation: "summary.price.reactiveAutomaticPriceCalculation",
      modalPriceCalculation: {
        realtimePrice: "summary.price.modalPriceCalculation.realtimePrice",
        recommendationDeactivation:
          "summary.price.modalPriceCalculation.recommendationDeactivation",
      },
      surveyLength: {
        SMALL: "summary.price.surveyLength.SMALL",
        MEDIUM: "summary.price.surveyLength.MEDIUM",
        LARGE: "summary.price.surveyLength.LARGE",
      },
      complexity: {
        SMALL: "summary.price.complexity.SMALL",
        MEDIUM: "summary.price.complexity.MEDIUM",
        LARGE: "summary.price.complexity.LARGE",
      },
      approx: "summary.price.approx",
      editDuration: "summary.price.editDuration",
      question: "summary.price.question",
      questions: "summary.price.questions",
    },
    authSection: {
      headline: "summary.authSection.headline",
      infoText: "summary.authSection.infoText",
      alreadyCustomer: "summary.authSection.alreadyCustomer",
      registerNewCustomer: "summary.authSection.registerNewCustomer",
      billData: "summary.authSection.billData",
    },
  },
  support: {
    headline: "support.headline",
    text: "support.text",
    button: "support.button",
    faq: "support.faq",
    support: "support.support",
  },
  quopinion: {
    openingHours: "quopinion.openingHours",
    phone: "quopinion.phone",
    phoneResearcher: "quopinion.phoneResearcher",
    phoneResearcherDirect: "quopinion.phoneResearcherDirect",
    supportPhone: "quopinion.supportPhone",
    your: "quopinion.your",
    participant: {
      your: "quopinion.participant.your",
      openingHoursSuffix: "quopinion.participant.openingHoursSuffix",
    },
    researcher: { openingHoursSuffix: "quopinion.researcher.openingHoursSuffix" },
    team: "quopinion.team",
  },
  confirm: {
    researcherHeadline: "confirm.researcherHeadline",
    email1Researcher: "confirm.email1Researcher",
    headline: "confirm.headline",
    eMail1: "confirm.eMail1",
    eMail2: "confirm.eMail2",
    check: "confirm.check",
    spamCheck: "confirm.spamCheck",
    postCheck: "confirm.postCheck",
    question1: "confirm.question1",
    under: "confirm.under",
    participant: {
      check: "confirm.participant.check",
      postCheck: "confirm.participant.postCheck",
      spamCheck: "confirm.participant.spamCheck",
      question1: "confirm.participant.question1",
    },
  },
  switch: { on: "switch.on", off: "switch.off" },
  dialog: {
    deleteQuestion: "dialog.deleteQuestion",
    resetQuestionnaire: {
      headline: "dialog.resetQuestionnaire.headline",
      description: "dialog.resetQuestionnaire.description",
    },
  },
  register: {
    newRegister: "register.newRegister",
    newRegisterDescription: "register.newRegisterDescription",
    headline: "register.headline",
    description: "register.description",
    personalInformation: {
      headline: "register.personalInformation.headline",
      salutation: "register.personalInformation.salutation",
      forename: "register.personalInformation.forename",
      validationName: "register.personalInformation.validationName",
      surname: "register.personalInformation.surname",
      industry: "register.personalInformation.industry",
      position: "register.personalInformation.position",
      addressWithHouseNr: "register.personalInformation.addressWithHouseNr",
      postalCode: "register.personalInformation.postalCode",
      postBox: "register.personalInformation.postBox",
      furtherData: "register.personalInformation.furtherData",
      industryRequired: "register.personalInformation.industryRequired",
      positionRequired: "register.personalInformation.positionRequired",
      salutationRequired: "register.personalInformation.salutationRequired",
      fornameRequired: "register.personalInformation.fornameRequired",
      surnameRequired: "register.personalInformation.surnameRequired",
      companyNameRequired: "register.personalInformation.companyNameRequired",
      addressDataRequired: "register.personalInformation.addressDataRequired",
      postalCodeRequired: "register.personalInformation.postalCodeRequired",
      cityRequired: "register.personalInformation.cityRequired",
      companyNameLength: "register.personalInformation.companyNameLength",
      cityLength: "register.personalInformation.cityLength",
      addressLength: "register.personalInformation.addressLength",
      postalCodeLength: "register.personalInformation.postalCodeLength",
      postBoxRequired: "register.personalInformation.postBoxRequired",
    },
    loginDetails: {
      headline: "register.loginDetails.headline",
      eMail: "register.loginDetails.eMail",
      repeatPassword: "register.loginDetails.repeatPassword",
      passwordRepeatRequired: "register.loginDetails.passwordRepeatRequired",
      passwortSecurity: {
        title: "register.loginDetails.passwortSecurity.title",
        bad: "register.loginDetails.passwortSecurity.bad",
        fair: "register.loginDetails.passwortSecurity.fair",
        medium: "register.loginDetails.passwortSecurity.medium",
        good: "register.loginDetails.passwortSecurity.good",
        strong: "register.loginDetails.passwortSecurity.strong",
      },
      privacy: {
        title: "register.loginDetails.privacy.title",
        titlePart1: "register.loginDetails.privacy.titlePart1",
        titleTermsConditions: "register.loginDetails.privacy.titleTermsConditions",
        titlePart2: "register.loginDetails.privacy.titlePart2",
        titlePrivacy: "register.loginDetails.privacy.titlePrivacy",
        titlePart3: "register.loginDetails.privacy.titlePart3",
        validation: "register.loginDetails.privacy.validation",
      },
      privacyTermsConditions: "register.loginDetails.privacyTermsConditions",
      extra: { title: "register.loginDetails.extra.title" },
    },
    participant: {
      headline: "register.participant.headline",
      description: "register.participant.description",
      disabled: "register.participant.disabled",
      step: "register.participant.step",
      opinion: {
        title: "register.participant.opinion.title",
        description: "register.participant.opinion.description",
        headline: "register.participant.opinion.headline",
        pageDescription: "register.participant.opinion.pageDescription",
        hint: "register.participant.opinion.hint",
        shortQuestionnaires: "register.participant.opinion.shortQuestionnaires",
        mediumQuestionnaires: "register.participant.opinion.mediumQuestionnaires",
        longQuestionnaires: "register.participant.opinion.longQuestionnaires",
        bigSelection: "register.participant.opinion.bigSelection",
        mediumSelection: "register.participant.opinion.mediumSelection",
        smallSelection: "register.participant.opinion.smallSelection",
        cent: "register.participant.opinion.cent",
        minutes: "register.participant.opinion.minutes",
        minute: "register.participant.opinion.minute",
        validation: "register.participant.opinion.validation",
      },
      data: {
        title: "register.participant.data.title",
        description: "register.participant.data.description",
        headline: "register.participant.data.headline",
        text: "register.participant.data.text",
        hint: "register.participant.data.hint",
        validationGender: "register.participant.data.validationGender",
        validationFirstName: "register.participant.data.validationFirstName",
        validationLastName: "register.participant.data.validationLastName",
        validationAge: "register.participant.data.validationAge",
        validationBirthday: "register.participant.data.validationBirthday",
        validationBirthdayDateType: "register.participant.data.validationBirthdayDateType",
        validationMinAge: "register.participant.data.validationMinAge",
        validationMaxAge: "register.participant.data.validationMaxAge",
        validationAgeValue: "register.participant.data.validationAgeValue",
        ageHint: "register.participant.data.ageHint",
        validationHousehold: "register.participant.data.validationHousehold",
        validationIncome: "register.participant.data.validationIncome",
        validationHometown: "register.participant.data.validationHometown",
        validationZip: "register.participant.data.validationZip",
        validationZipLengthGeneral: "register.participant.data.validationZipLengthGeneral",
        validationZipLengthMin: "register.participant.data.validationZipLengthMin",
        validationZipLengthMax: "register.participant.data.validationZipLengthMax",
        validationZipJustNumbers: "register.participant.data.validationZipJustNumbers",
        attention: {
          title: "register.participant.data.attention.title",
          validation: "register.participant.data.attention.validation",
          value: {
            facebook: "register.participant.data.attention.value.facebook",
            google: "register.participant.data.attention.value.google",
            instagram: "register.participant.data.attention.value.instagram",
            linkedin: "register.participant.data.attention.value.linkedin",
            other: "register.participant.data.attention.value.other",
            otherParticipant: "register.participant.data.attention.value.otherParticipant",
            quotas: "register.participant.data.attention.value.quotas",
            xing: "register.participant.data.attention.value.xing",
            youtube: "register.participant.data.attention.value.youtube",
          },
        },
        usableForQuotasStudies: {
          info: "register.participant.data.usableForQuotasStudies.info",
          shortTitle: "register.participant.data.usableForQuotasStudies.shortTitle",
          title: "register.participant.data.usableForQuotasStudies.title",
          validation: "register.participant.data.usableForQuotasStudies.validation",
          value: {
            yes: "register.participant.data.usableForQuotasStudies.value.yes",
            no: "register.participant.data.usableForQuotasStudies.value.no",
            alreadyTn: "register.participant.data.usableForQuotasStudies.value.alreadyTn",
          },
        },
        validationEducation: "register.participant.data.validationEducation",
        validationFamilyStatus: "register.participant.data.validationFamilyStatus",
        validationChildren: "register.participant.data.validationChildren",
      },
      fullUse: "register.participant.fullUse",
      account: {
        headline: "register.participant.account.headline",
        description: "register.participant.account.description",
        privacyTermsConditions: "register.participant.account.privacyTermsConditions",
      },
    },
  },
  participant: {
    surveyOverview: {
      second: "participant.surveyOverview.second",
      seconds: "participant.surveyOverview.seconds",
      minute: "participant.surveyOverview.minute",
      minutes: "participant.surveyOverview.minutes",
      noAccessOnSurveyInfos: "participant.surveyOverview.noAccessOnSurveyInfos",
    },
    opinionValue: {
      questionForValue: "participant.opinionValue.questionForValue",
      impactOnSurveysText: "participant.opinionValue.impactOnSurveysText",
      bigSelection: "participant.opinionValue.bigSelection",
      mediumSelection: "participant.opinionValue.mediumSelection",
      smallSelection: "participant.opinionValue.smallSelection",
      minutesAbbrev: "participant.opinionValue.minutesAbbrev",
      priceS: "participant.opinionValue.priceS",
      priceM: "participant.opinionValue.priceM",
      priceL: "participant.opinionValue.priceL",
      validation: {
        priceSpriceM: "participant.opinionValue.validation.priceSpriceM",
        greaterZero: "participant.opinionValue.validation.greaterZero",
        priceMpriceS: "participant.opinionValue.validation.priceMpriceS",
        priceMpriceL: "participant.opinionValue.validation.priceMpriceL",
        priceLpriceM: "participant.opinionValue.validation.priceLpriceM",
        maxValue: "participant.opinionValue.validation.maxValue",
      },
    },
    profile: {
      yourProfile: "participant.profile.yourProfile",
      loginData: "participant.profile.loginData",
      furtherData: "participant.profile.furtherData",
      personalData: "participant.profile.personalData",
      edit: {
        userName: "participant.profile.edit.userName",
        email: "participant.profile.edit.email",
        firstName: "participant.profile.edit.firstName",
        lastName: "participant.profile.edit.lastName",
        gender: "participant.profile.edit.gender",
        phoneNr: "participant.profile.edit.phoneNr",
        street: "participant.profile.edit.street",
        houseNr: "participant.profile.edit.houseNr",
        city: "participant.profile.edit.city",
      },
    },
    pauseAccount: {
      headline: "participant.pauseAccount.headline",
      infoText: "participant.pauseAccount.infoText",
      pauseButton: "participant.pauseAccount.pauseButton",
      deregisterAccount: "participant.pauseAccount.deregisterAccount",
    },
    deleteAccount: {
      headline: "participant.deleteAccount.headline",
      infoText: "participant.deleteAccount.infoText",
      confirmDelete: "participant.deleteAccount.confirmDelete",
      confirmDeleteHeadline: "participant.deleteAccount.confirmDeleteHeadline",
      confirmDeleteTitle: "participant.deleteAccount.confirmDeleteTitle",
      confirmDeleteText: "participant.deleteAccount.confirmDeleteText",
      confirmDeleteText1: "participant.deleteAccount.confirmDeleteText1",
      confirmDeleteText2: "participant.deleteAccount.confirmDeleteText2",
      confirmDeleteText3: "participant.deleteAccount.confirmDeleteText3",
    },
    menu: {
      opinionValue: "participant.menu.opinionValue",
      profile: "participant.menu.profile",
      attributes: "participant.menu.attributes",
      support: "participant.menu.support",
      earnings: "participant.menu.earnings",
      surveys: "participant.menu.surveys",
      privacy: "participant.menu.privacy",
      termsOfUse: "participant.menu.termsOfUse",
      faqAndSupport: "participant.menu.faqAndSupport",
      dataSecurity: "participant.menu.dataSecurity",
      legalTermsConditions: "participant.menu.legalTermsConditions",
    },
    screeners: {
      screener: "participant.screeners.screener",
      daily: "participant.screeners.daily",
      dailiesHeadline: "participant.screeners.dailiesHeadline",
      headline: "participant.screeners.headline",
      completed: "participant.screeners.completed",
      description: "participant.screeners.description",
      categories: {
        home: "participant.screeners.categories.home",
        health: "participant.screeners.categories.health",
        freetime: "participant.screeners.categories.freetime",
        mobility: "participant.screeners.categories.mobility",
        technology: "participant.screeners.categories.technology",
      },
      level: {
        title: "participant.screeners.level.title",
        bronze: "participant.screeners.level.bronze",
        silver: "participant.screeners.level.silver",
        gold: "participant.screeners.level.gold",
        accessibility: {
          headline: "participant.screeners.level.accessibility.headline",
          description: "participant.screeners.level.accessibility.description",
        },
        upgrade: "participant.screeners.level.upgrade",
        upgradeNewQuestions: "participant.screeners.level.upgradeNewQuestions",
      },
      levelDescription: {
        bronze: "participant.screeners.levelDescription.bronze",
        silver: "participant.screeners.levelDescription.silver",
        gold: "participant.screeners.levelDescription.gold",
      },
      attributesItem: {
        question: "participant.screeners.attributesItem.question",
        questions: "participant.screeners.attributesItem.questions",
        headline: "participant.screeners.attributesItem.headline",
      },
      modify: {
        headline: "participant.screeners.modify.headline",
        description: "participant.screeners.modify.description",
        ctaHeadline: "participant.screeners.modify.ctaHeadline",
        ctaDescription: "participant.screeners.modify.ctaDescription",
      },
    },
  },
  validation: {
    login: {
      emailNotValid: "validation.login.emailNotValid",
      emailRequired: "validation.login.emailRequired",
      emailRequiredParticipant: "validation.login.emailRequiredParticipant",
      passwordMinLength: "validation.login.passwordMinLength",
      passwordRequired: "validation.login.passwordRequired",
      passwordRequiredParticipant: "validation.login.passwordRequiredParticipant",
      passwordCheckParticipant: "validation.login.passwordCheckParticipant",
      emailCheckParticipant: "validation.login.emailCheckParticipant",
    },
    participant: {
      profile: {
        phoneNrType: "validation.participant.profile.phoneNrType",
        streetLength: "validation.participant.profile.streetLength",
        cityLength: "validation.participant.profile.cityLength",
        houseNrFormat: "validation.participant.profile.houseNrFormat",
      },
    },
    name: { required: "validation.name.required", length: "validation.name.length" },
  },
  payoutPage: {
    yourAccount: "payoutPage.yourAccount",
    currentBalance: "payoutPage.currentBalance",
    payoutRequest: "payoutPage.payoutRequest",
    payout: {
      title: "payoutPage.payout.title",
      bookVoucher: "payoutPage.payout.bookVoucher",
      voucher: "payoutPage.payout.voucher",
      donation: "payoutPage.payout.donation",
      choosePayout: "payoutPage.payout.choosePayout",
      payoutAmount: "payoutPage.payout.payoutAmount",
      amountChoiceRequirement: "payoutPage.payout.amountChoiceRequirement",
      currency: "payoutPage.payout.currency",
      summary: "payoutPage.payout.summary",
      payoutMethod: "payoutPage.payout.payoutMethod",
      amount: "payoutPage.payout.amount",
      bookingTimestamp: "payoutPage.payout.bookingTimestamp",
      immediately: "payoutPage.payout.immediately",
      immediatelyDisclaimer: "payoutPage.payout.immediatelyDisclaimer",
      startOfMonthDisclaimer: "payoutPage.payout.startOfMonthDisclaimer",
      pleaseEnter: "payoutPage.payout.pleaseEnter",
      success: {
        title: "payoutPage.payout.success.title",
        message: "payoutPage.payout.success.message",
        messageStartOfMonth: "payoutPage.payout.success.messageStartOfMonth",
      },
      failure: {
        title: "payoutPage.payout.failure.title",
        message: "payoutPage.payout.failure.message",
      },
      options: {
        paypal: {
          title: "payoutPage.payout.options.paypal.title",
          buttonText: "payoutPage.payout.options.paypal.buttonText",
          email: "payoutPage.payout.options.paypal.email",
        },
        doctor: {
          title: "payoutPage.payout.options.doctor.title",
          buttonText: "payoutPage.payout.options.doctor.buttonText",
        },
        amazon: {
          title: "payoutPage.payout.options.amazon.title",
          buttonText: "payoutPage.payout.options.amazon.buttonText",
        },
        graff: {
          title: "payoutPage.payout.options.graff.title",
          linkText: "payoutPage.payout.options.graff.linkText",
          buttonText: "payoutPage.payout.options.graff.buttonText",
        },
      },
    },
    creditHistory: {
      type: {
        SURVEY_COMPLETION: "payoutPage.creditHistory.type.SURVEY_COMPLETION",
        PAYOUT: "payoutPage.creditHistory.type.PAYOUT",
        TRANSFER: "payoutPage.creditHistory.type.TRANSFER",
      },
      reason: {
        paypal: "payoutPage.creditHistory.reason.paypal",
        aerzteohnegrenzen: "payoutPage.creditHistory.reason.aerzteohnegrenzen",
        amazon: "payoutPage.creditHistory.reason.amazon",
        graff: "payoutPage.creditHistory.reason.graff",
        TRANSFER: "payoutPage.creditHistory.reason.TRANSFER",
      },
    },
  },
  confirmation: {
    researcher: {
      success: "confirmation.researcher.success",
      failure: "confirmation.researcher.failure",
    },
  },
  errorhandling: {
    "0": "errorhandling.0",
    "1": "errorhandling.1",
    "2": "errorhandling.2",
    "3": "errorhandling.3",
    "4": "errorhandling.4",
    "5": "errorhandling.5",
    "6": "errorhandling.6",
    "7": "errorhandling.7",
    "8": "errorhandling.8",
    "9": "errorhandling.9",
    "10": "errorhandling.10",
    "11": "errorhandling.11",
    "12": "errorhandling.12",
    "13": "errorhandling.13",
    "14": "errorhandling.14",
    "15": "errorhandling.15",
    "19": "errorhandling.19",
    "20": "errorhandling.20",
    "21": "errorhandling.21",
    "22": "errorhandling.22",
    "23": "errorhandling.23",
    "24": "errorhandling.24",
    "25": "errorhandling.25",
    "26": "errorhandling.26",
    "27": "errorhandling.27",
    "28": "errorhandling.28",
    "29": "errorhandling.29",
    "30": "errorhandling.30",
    "31": "errorhandling.31",
    "32": "errorhandling.32",
    "33": "errorhandling.33",
    "34": "errorhandling.34",
    "35": "errorhandling.35",
  },
};
