import React, { PureComponent } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { WithStyles, withStyles } from "@material-ui/styles";
import { QuopinionTheme } from "../../../../constants/Theme";
import { createStyles, Typography } from "@material-ui/core";
import { translations } from "../../../../constants/lang/translation";
import CardWithIcon from "../components/CardWithIcon";
import { compose } from "recompose";
import i18next from "i18next";
import { Cash, Attribute } from "../../../../assets/icon";
import { withRouter, RouteComponentProps } from "react-router";
import Badge from "../../../../common/Layout/components/Badge";
import { connect } from "react-redux";
import { RootState } from "../../../../modules";
import { route } from "../";

interface OwnProps {}

interface StateProps {
  registrationSteps: string[];
}

const styles = (theme: QuopinionTheme) =>
  createStyles({
    contentWrapper: {
      // padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
    },
    description: {
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(6)}px`,
    },
    badge: {
      textTransform: "none",
      marginTop: theme.spacing(4),
      zIndex: 10,
    },
    line: {
      border: 0,
      borderBottom: `1px dashed #99a9b9`,
      background: "transparent",
      position: "absolute",
      transform: "rotate(90deg)",
      width: 300,
      top: "50%",
      left: "-75px",
      zIndex: 0,
    },
    cardWrapper: {
      position: "relative",
    },
  });

type Props = OwnProps &
  StateProps &
  WithTranslation &
  WithStyles<typeof styles> &
  RouteComponentProps;

class RegistrationStart extends PureComponent<Props> {
  registerSteps = [
    {
      title: i18next.t(translations.register.participant.opinion.title),
      description: i18next.t(translations.register.participant.opinion.description),
      icon: <Cash />,
      badgeTitle: i18next.t(translations.register.participant.step) + " 1",
      link: `${route}/opinion`,
      indicator: "opinion",
    },
    {
      title: i18next.t(translations.register.participant.data.title),
      description: i18next.t(translations.register.participant.data.description),
      icon: <Attribute />,
      badgeTitle: i18next.t(translations.register.participant.step) + " 2",
      link: `${route}/attributes`,
      indicator: "attributes",
    },
  ];

  redirectToPage = (link: string) => {
    this.props.history.push(link);
  };

  checkClickable = (step: string) => {
    if (this.props.registrationSteps.length === 0) {
      if (step === "opinion") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  render() {
    const { classes, t } = this.props;

    return (
      <>
        <Typography variant="h1">{t(translations.register.participant.headline)}</Typography>
        <Typography variant="subtitle1" className={classes.description}>
          {t(translations.register.participant.description)}
        </Typography>
        <Typography variant="h3" className={classes.description}>
          {t(translations.register.participant.disabled)}
        </Typography>
        <div className={classes.cardWrapper}>
          <hr className={classes.line} />
          {this.registerSteps.map((step) => (
            <CardWithIcon
              key={step.title}
              title={step.title}
              description={step.description}
              icon={step.icon}
              badgeTitle={step.badgeTitle}
              onClick={() => this.redirectToPage(step.link)}
              finished={this.props.registrationSteps.includes(step.indicator)}
              disabled={true}
            />
          ))}
          <Badge
            className={classes.badge}
            width="100%"
            color="secondary"
            title={t(translations.register.participant.fullUse)}
            style={{ opacity: this.props.registrationSteps.length > 1 ? 1 : 0.4 }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ uiState }: RootState) => {
  return {
    registrationSteps: uiState.registrationSteps,
  };
};

const mapDispatchToProps = {};
export default compose<Props, OwnProps>(
  withTranslation(),
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(RegistrationStart);
